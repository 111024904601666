<template>
  <div>
    <v-row class="mb-5 d-flex flex-row">
      <v-col cols="4">
        <v-card class="d-flex flex-column" style="background: #002441">
          <v-card-title style="font-size: 20px; color: white" class="pa-3">
            Total Payable
            <div style="background: #002441; color: white" class="ml-3">
              {{ total_payable }}
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="d-flex flex-column" style="background: #002441">
          <v-card-title style="font-size: 20px; color: white" class="pa-3">
            Total Receiveable
            <div style="background: #002441; color: white" class="ml-3">
              {{ total_receivableable }}
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="transactions_headers"
      :items="mother_transactions"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-0"
    >
    </v-data-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: true,
        total_payable: 0,
        total_receivableable: 0,
        transactions_headers: [
          {
            text: "Invoice ID",
            value: "invoice_id",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
          },
          {
            text: "Order Date",
            align: "start",
            sortable: false,
            value: "ordered_date",
            class: "custom-header black--text",
            divider: true,
          },
          {
            text: "Total Price",
            value: "price_total",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
            align: "center",
          },
          {
            text: "Payment Method",
            value: "payment_method",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
            align: "center",
          },
          {
            text: "Payable",
            value: "total_admin_payable",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
          },
          {
            text: "Recieveable",
            value: "total_admin_receiveable",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
            align: "center",
          },

          {
            text: "Merchant Payable",
            value: "total_seller_payable",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
          },
          // {
          //   text: "Deposited Currency",
          //   value: "iron",
          //   class: "custom-header black--text",
          //   divider: true,
          //   sortable: false,
          // },
          {
            text: "Merchant Receiveable",
            value: "total_seller_receiveable",
            class: "custom-header black--text",
            divider: true,
            sortable: false,
          },
          // {
          //   text: "Action",
          //   value: "approve_action",
          //   class: "custom-header black--text",
          //   divider: true,
          //   sortable: false,
          //   align: "center",
          // },
        ],
        mother_transactions: [],
        user: {},
      };
    },

    methods: {
      getIndividualSellerTransactions() {
        axios
          .get("Cart/individual_merchant_commission/" + this.user.user_id + "/")
          .then((response) => {
            this.loading = false;
            if (response.data.success && response.data.data) {
              this.mother_transactions = response.data.data;
            } else {
              this.mother_transactions = [];
            }
          })
          .catch((err) => {
            this.loading = false;
          });

        axios
          .get(
            "Cart/total_individual_merchant_commission/" + this.user.user_id + "/"
          )
          .then((response) => {
            if (response.data.success) {
              this.total_payable = response.data.overall_receiveable;
              this.total_receivableable = response.data.overall_payable;
            }
          });
      },
    },

    created() {
      this.user = JSON.parse(localStorage.getItem("user"));
      if (this.user.user_id) {
        this.getIndividualSellerTransactions();
      }
    },
  };
</script>

<style>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.19rem;
    height: 48px;
  }
</style>
